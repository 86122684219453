#projects {
  max-width: 600px;
  margin: 0 auto;
}

#projects h2 {
  font-size: 32px;
  margin-bottom: 24px;
  text-align: center;
}
